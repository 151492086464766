import React from 'react'
import Loadable from 'react-loadable'

function Loading(props) {
  return <div className="vnt-loading"></div>
}

// COMMON
const AsyncLogin = Loadable({
  loader: () => import('../components/login-register/Login'),
  loading: Loading
})

const AsyncRegister = Loadable({
  loader: () => import('../components/login-register/Register'),
  loading: Loading
})

const AsyncForgotPassword = Loadable({
  loader: () => import('../components/login-register/ForgotPassword'),
  loading: Loading
})

const AsyncResetPassword = Loadable({
  loader: () => import('../components/login-register/ResetPassword'),
  loading: Loading
})

const AsyncNotFound = Loadable({
  loader: () => import('../components/NotFound'),
  loading: Loading
})

// CUSTOMER
const AsyncDashboard = Loadable({
  loader: () => import('../components/customer/Dashboard'),
  loading: Loading
})

const AsyncKeys = Loadable({
  loader: () => import('../components/customer/key/Keys'),
  loading: Loading
})

const AsyncBilling = Loadable({
  loader: () => import('../components/customer/billing/Billing'),
  loading: Loading
})

const AsyncBillingDetails = Loadable({
  loader: () => import('../components/customer/billing/BillingDetails'),
  loading: Loading
})

const AsyncPayment = Loadable({
  loader: () => import('../components/customer/payment/Payment'),
  loading: Loading
})

const AsyncMap = Loadable({
  loader: () => import('../components/customer/map/Map'),
  loading: Loading
})

const AsyncLayer = Loadable({
  loader: () => import('../components/customer/map/Layer'),
  loading: Loading
})

const AsyncVNPayReturn = Loadable({
  loader: () => import('../components/customer/billing/VNPayReturn'),
  loading: Loading
})
const AsyncPaypalReturn = Loadable({
  loader: () => import('../components/customer/billing/PaypalReturn'),
  loading: Loading
})
const AsyncSupportRequest = Loadable({
  loader: () => import('../components/customer/support/spRequest'),
  loading: Loading
})
const AsyncSupportAddRequest = Loadable({
  loader: () => import('../components/customer/support/form/addQuestion'),
  loading: Loading
})
const AsyncSupportFaqs = Loadable({
  loader: () => import('../components/customer/support/faqs'),
  loading: Loading
})
// statics
const AsyncStatistics = Loadable({
  loader: () => import('../components/customer/statistics/Statistics'),
  loading: Loading
})

const AsyncConfirmUserRegister = Loadable({
  loader: () => import('../components/customer/user-settings/ConfirmUserRegister'),
  loading: Loading
})

const AsyncSettingsLayout = Loadable({
  loader: () => import('../components/customer/user-settings/SettingsLayout'),
  loading: Loading
})

// ADMIN
const AsyncOverView = Loadable({
  loader: () => import('../components/admin/overview/OverView'),
  loading: Loading
})

const AsyncSalesAccManagement = Loadable({
  loader: () => import('../components/admin/saleAccManagement/saleAccManagement'),
  loading: Loading
})

const AsyncUserManagement = Loadable({
  loader: () => import('../components/admin/user-management/UserManagement.js'),
  loading: Loading
})

const AsyncKeyAndBillsByUser = Loadable({
  loader: () => import('../components/admin/user-management/KeyAndBillCustomer'),
  loading: Loading
})

const AsyncBalanceLogByUser = Loadable({
  loader: () => import('../components/admin/user-management/BalanceLogUser'),
  loading: Loading
})

const AsyncCouponLogByUser = Loadable({
  loader: () => import('../components/admin/user-management/CouponLogUser'),
  loading: Loading
})

const AsyncAddUser = Loadable({
  loader: () => import('../components/admin/user-management/AddUser'),
  loading: Loading
})

const AsyncBillingManagement = Loadable({
  loader: () => import('../components/admin/billing-management/BillingManagement'),
  loading: Loading
})

const AsyncUsageStatistics = Loadable({
  loader: () => import('../components/admin/usage-statistics/UsageStatistics'),
  loading: Loading
})

const AsyncUsageStatisticsPerUser = Loadable({
  loader: () => import('../components/admin/usage-statistics/UsageStatisticsPerUser'),
  loading: Loading
})

const AsyncNotifications = Loadable({
  loader: () => import('../components/admin/notifications/Notifications'),
  loading: Loading
})

const AsyncAdminSupport = Loadable({
  loader: () => import('../components/admin/support/support'),
  loading: Loading
})

const AsyncAdminFaqs = Loadable({
  loader: () => import('../components/admin/support/faqs'),
  loading: Loading
})

const AsyncAdminLogs = Loadable({
  loader: () => import('../components/admin/logs/index.js'),
  loading: Loading
})

const AsyncAdminCoupons = Loadable({
  loader: () => import('../components/admin/coupons/index.js'),
  loading: Loading
})
const AsyncAdminCampaigns = Loadable({
  loader: () => import('../components/admin/campaigns/index.js'),
  loading: Loading
})

const AsyncAdminChangeEmail = Loadable({
  loader: () => import('../components/admin/change-email/index.js'),
  loading: Loading
})

const AsyncCouponsInType = Loadable({
  loader: () => import('../components/admin/coupons/CouponInType'),
  loading: Loading
})

const AsyncAdminSupportRequest = Loadable({
  loader: () => import('../components/admin/support/spRequest'),
  loading: Loading
})

const AsyncSettings = Loadable({
  loader: () => import('../components/admin/settings/Settings'),
  loading: Loading
})

const AsyncSendVerify = Loadable({
  loader: () => import('../components/login-register/SendVerify'),
  loading: Loading
})

const AsyncMaintain = Loadable({
  loader: () => import('../components/Maintain'),
  loading: Loading
})

const AsyncPlans = Loadable({
  loader: () => import('../components/admin/plans'),
  loading: Loading
})

export const appRoutes = [
  // common
  {
    path: '/user/verify',
    name: 'ConfirmUserRegister',
    component: AsyncConfirmUserRegister,
    exact: true,
    isProtected: false,
    layout: 'customer'
  },
  {
    path: '/send-verify',
    name: 'SendVerifyEmail',
    component: AsyncSendVerify,
    exact: true,
    isProtected: false,
    layout: 'customer'
  },
  {
    path: '/login',
    name: 'Login',
    component: AsyncLogin,
    exact: true,
    isProtected: false,
    layout: 'customer'
  },
  {
    path: '/register',
    name: 'Register',
    component: AsyncRegister,
    exact: false,
    layout: 'customer'
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: AsyncForgotPassword,
    exact: false,
    layout: 'customer'
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: AsyncResetPassword,
    exact: false,
    layout: 'customer'
  },

  // customer
  {
    path: '/',
    name: 'Dashboard',
    component: AsyncDashboard,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'customer', 'support'],
    layout: 'customer'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: AsyncDashboard,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'customer'
  },
  {
    path: '/keys',
    name: 'Keys',
    component: AsyncKeys,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'customer'
  },
  {
    path: '/billing',
    name: 'Billing',
    component: AsyncBilling,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'customer'
  },
  {
    path: '/billing/vnpay_return',
    name: 'VNPayReturn',
    component: AsyncVNPayReturn,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'customer'
  },
  {
    path: '/billing/paypal_return',
    name: 'PaypalReturn',
    component: AsyncPaypalReturn,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'customer'
  },
  {
    path: '/billing/:id',
    name: 'BillingDetails',
    component: AsyncBillingDetails,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'customer'
  },
  {
    path: '/payment',
    name: 'Payment',
    component: AsyncPayment,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'customer'
  },
  {
    path: '/maps',
    name: 'Map',
    component: AsyncMap,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'customer'
  },
  {
    path: '/maps/:id',
    name: 'Layer',
    component: AsyncLayer,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'customer'
  },
  {
    path: '/support/request',
    name: 'SupportRequest',
    component: AsyncSupportRequest,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'customer'
  },
  {
    path: '/support/request/addQuestion',
    name: 'addQuestion',
    component: AsyncSupportAddRequest,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'customer'
  },
  {
    path: '/faqs',
    name: 'FAQsCustomer',
    component: AsyncSupportFaqs,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'faqs'
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: AsyncStatistics,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'customer'
  },
  {
    path: '/settings/:page',
    name: 'SettingsLayout',
    component: AsyncSettingsLayout,
    exact: true,
    isProtected: true,
    onlyAllow:  ['admin', 'customer', 'support'],
    layout: 'customer'
  },

  // admin
  {
    path: '/administrator',
    name: 'AdminOverview',
    component: AsyncOverView,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'support'],
    layout: 'admin'
  },
  {
    path: '/administrator/overview',
    name: 'AdminOverview',
    component: AsyncOverView,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'support'],
    layout: 'admin'
  },
  {
    path: '/administrator/sales-account-management',
    name: 'SalesAccManagement',
    component: AsyncSalesAccManagement,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/user-management',
    name: 'UserManagement',
    component: AsyncUserManagement,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'support'],
    layout: 'admin'
  },
  {
    path: '/administrator/user-management/create-user',
    name: 'AddUser',
    component: AsyncAddUser,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'support'],
    layout: 'admin'
  },
  {
    path: '/administrator/user-management/edit-user',
    name: 'EditUser',
    component: AsyncAddUser,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'support'],
    layout: 'admin'
  },
  {
    path: '/administrator/user-management/key-and-bill',
    name: 'KeyAndBills',
    component: AsyncKeyAndBillsByUser,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/user-management/balance-log',
    name: 'BalanceLogUser',
    component: AsyncBalanceLogByUser,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/user-management/coupon-log',
    name: 'COuponLogUser',
    component: AsyncCouponLogByUser,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/billing-management',
    name: 'BillingManagement',
    component: AsyncBillingManagement,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/usage-statistics',
    name: 'UsageStatistics',
    component: AsyncUsageStatistics,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/usage-statistics-per-user',
    name: 'UsageStatisticsPerUser',
    component: AsyncUsageStatisticsPerUser,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/notifications',
    name: 'Notifications',
    component: AsyncNotifications,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'support'],
    layout: 'admin'
  },
  {
    path: '/administrator/support',
    name: 'AdminSupport',
    component: AsyncAdminSupport,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'support'],
    layout: 'admin'
  },
  {
    path: '/administrator/support/request',
    name: 'AdminSupportRequest',
    component: AsyncAdminSupportRequest,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'support'],
    layout: 'admin'
  },
  {
    path: '/administrator/faqs',
    name: 'FAQs',
    component: AsyncAdminFaqs,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'support'],
    layout: 'admin'
  },
  {
    path: '/administrator/logs',
    name: 'logs',
    component: AsyncAdminLogs,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'support'],
    layout: 'admin'
  },
  {
    path: '/administrator/coupons',
    name: 'coupons',
    component: AsyncAdminCoupons,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/campaigns',
    name: 'campaigns',
    component: AsyncAdminCampaigns,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'support'],
    layout: 'admin'
  },
  {
    path: '/administrator/change-email',
    name: 'email',
    component: AsyncAdminChangeEmail,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'support'],
    layout: 'admin'
  },
  {
    path: '/administrator/coupons/coupon-types',
    name: 'coupons',
    component: AsyncCouponsInType,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/plans',
    name: 'plans',
    component: AsyncPlans,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'support'],
    layout: 'admin'
  },
  {
    path: '/administrator/settings',
    name: 'AdminSettings',
    component: AsyncSettings,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },

  {
    path: '/maintain',
    name: 'Maintain',
    component: AsyncMaintain,
    exact: false,
    layout: ''
  },

  // 404
  {
    path: '',
    name: '404',
    component: AsyncNotFound,
    exact: false,
    layout: 'customer'
  }
]
