import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import img_logo from '../../assets/images/goong-logo-2x.png'
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import { FacebookOutlined } from '@ant-design/icons';


function Footer(props) {
  const { location } = props.history
  const { t } = useTranslation('common')

  const isActive = path => {
    return location.pathname === path ? 'active' : ''
  }
  return (
    <footer className={props.classFooter} style={{ backgroundColor: 'white' }}>
      <section className="goong-account">
        <div className="container goong-container">
          <div className="goong-name">
            <h1 className="goong-title" >{t('company_name')}</h1>
            <p className="goong-title" style={{ color: "#172636" }}>{t('address')}</p>
            <p className="goong-title" style={{ color: "#172636" }}>{t('license')}</p>
            <p className="goong-title" style={{ color: "#172636" }}>{t('email')}: <a href="mailto:admin@goong.io" target="_blank">{t('email_address')}</a></p>
            <p className="goong-title" style={{ color: "#172636" }}>{t('customer_service')}: <a href="tel:+84904522538" target="_blank">{t('account_support_tel')}</a> {t('account_support')} - <a href="tel:+84869697502" target="_blank">{t('technical_support_tel')}</a> {t('technical_support')}</p>
          </div>
          <div className="goong-inform">
            <h1 className="goong-title">{t('infor_detail')}</h1>
            <a href="https://goong.io/" target="_blank" className="goong-subtitle" ><p style={{ marginBottom: "16px" }}>{t('home')}</p></a>
            <a href="https://docs.goong.io/javascript/" target="_blank" className="goong-subtitle" ><p style={{ marginBottom: "16px" }}>{t('documents')}</p></a>
            <a href="https://goong.io/about-us" target="_blank" className="goong-subtitle" ><p style={{ marginBottom: "16px" }}>{t('about_us')}</p></a>
            <a href="https://goong.io/terms" target="_blank" className="goong-subtitle" ><p style={{ marginBottom: "16px" }}>{t('privacy_terms')}</p></a>
          </div>
          <div className="goong-contact">
            <h1 className="goong-title">{t('contact')}</h1>
            <div class="goong-connect">
              <a href="https://www.facebook.com/GoongMaps" target="_blank"><FacebookOutlined className="goong-icon" /></a>
            </div>
            <p className="goong-subtitle">© 2019 Goong Maps, {t('all_right_reseverd')}</p>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default withRouter(Footer)
