import { USER_SESSION_KEY, MONEY_TO_VNP } from '../constants_en'

export const saveUserSession = data => {
  return window.localStorage.setItem(USER_SESSION_KEY, JSON.stringify(data))
}

export const removeUserSession = () => {
  window.localStorage.removeItem('show_verify_after_create')
  return window.localStorage.removeItem(USER_SESSION_KEY)
}

export const getUserSession = () => {
  const data = window.localStorage.getItem(USER_SESSION_KEY)
  return data ? JSON.parse(data) : null
}

export const getLanguage = () => {
  const data = window.localStorage.getItem("language")
  return data ? data : 'vi'
}

export const isLoggedIn = () => {
  return !!window.localStorage.getItem(USER_SESSION_KEY)
}

export const isAdmin = () => {
  const user = getUserSession()
  return user && Array.isArray(user?.roles) && user?.roles.includes('admin')
}

export const isSupport = () => {
  const user = getUserSession()
  return user && Array.isArray(user?.roles) && user?.roles.includes('support')
}

export const isHavePermission = (roles = [], onlyAllow) => {
  let is_has_per = false
  for (let i = 0; i < roles.length; i++) {
    if (Array.isArray(onlyAllow) && onlyAllow.length > 0) {
      if (onlyAllow.includes(roles[i].toLowerCase())) {
        is_has_per = true
        break
      }
    } else {
      is_has_per = true
      break
    }
  }
  return is_has_per
}

export const getFullName = (first_name, last_name) => {
  return (first_name || '') + ' ' + (last_name || '')
}

export const removeUserSessionWhenExpired = () => {
  window.localStorage.removeItem(USER_SESSION_KEY)
  window.location.href = window.location.origin + '/login'
}

// phone form submission handler
export const smsVerify = (phoneNumber, countryCode = '+84', callback) => {
  window.AccountKit.login(
    'PHONE',
    { countryCode: countryCode, phoneNumber: phoneNumber }, // will use default values if not specified
    function(response) {
      if (response.status === 'PARTIALLY_AUTHENTICATED') {
        // const code = response.code;
        // const csrf = response.state;
        // callback()
        // Send code to server to exchange for access token
      } else if (response.status === 'NOT_AUTHENTICATED') {
        // handle authentication failure
      } else if (response.status === 'BAD_PARAMS') {
        // handle bad parameters
      }
    }
  )
}

export const getHeaders = () => {
  const user = getUserSession()
  return {
    'Content-Type': 'application/json',
    Authorization: user ? 'Bearer ' + user['token'] : '',
    'Accept-Language': 'vi'
  }
}

export const saveMoneyToVnp = data => {
  return window.localStorage.setItem(MONEY_TO_VNP, data)
}

export const getMoneyToVnp = () => {
  const data = window.localStorage.getItem(MONEY_TO_VNP)
  return data 
}
