import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/styles.css';
import 'react-notifications/lib/notifications.css';
import App from './components/layout/App';
import * as serviceWorker from './serviceWorker';
import {Router} from "react-router-dom";
import {Provider} from 'react-redux';
import store from './redux/store';

require('dotenv').config()

const createHistory = require('history').createBrowserHistory

export const history = createHistory()

const rootElement = (
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
)

window.addEventListener('message', (e) => {
  if(e.data === "TOKEN"){
    window.tokenOrigins = e;
  }
});

window.addEventListener('storage', (e) => {
  // When local storage changes, dump the list to
  // the console.
  if(e.key === "goong_user_client" && !e.newValue) {
    window.location.href = "/login";
  };    
});

ReactDOM.render(rootElement, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
