import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import NavBarItemAvatar from './NavBarItemAvatar'
import { getUserSession, getLanguage } from '../../utils/user-func'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import ApiService from '../../api-services/request-services'

function NavBar(props) {
  const location = props.location
  const { t, i18n } = useTranslation('common')
  const [lang, setLang] = useState(getLanguage() ? getLanguage() : 'en')
  const isActive = path => {
    return (location.pathname === '/' && path === '/dashboard') ||
      (location.pathname.includes('/billing/') && path === '/billing') ||
      (location.pathname.includes('/maps/') && path === '/maps') ||
      location.pathname === path
      ? 'active'
      : ''
  }

  const changeLanguage = lang => {
    i18n.changeLanguage(lang)
    setLang(lang)
    window.localStorage.setItem('language', lang)
    ApiService.defaults.headers.common['Accept-Language'] = lang
  }

  const ListLinks = ({ isLoggedIn }) => {
    if (isLoggedIn) {
      return (
        <React.Fragment>
          <li className={`goong-navbar__item ${isActive('/dashboard')}`}>
            <Link to={'/dashboard'} className="goong-navbar__link-dashboard">
              {t('dashboard')}
            </Link>
          </li>
          <li className={`goong-navbar__item ${isActive('/keys')}`}>
            <Link to={'/keys'} className="goong-navbar__link-dashboard">
              Keys
            </Link>
          </li>
          <li className={`goong-navbar__item ${isActive('/maps')}`}>
            <Link to={'/maps'} className="goong-navbar__link-dashboard">
              {t('create_map')}
            </Link>
          </li>
          <li className={`goong-navbar__item ${isActive('/statistics')}`}>
            <Link to={'/statistics'} className="goong-navbar__link-dashboard">
              {t('statistics')}
            </Link>
          </li>
          <li className={`goong-navbar__item ${isActive('/billing')}`}>
            <Link to={'/billing'} className="goong-navbar__link-dashboard">
              {t('billing')}
            </Link>
          </li>
          <li className={`goong-navbar__item ${isActive('/payment')}`}>
            <Link to={'/payment'} className="goong-navbar__link-dashboard">
              {t('payment')}
            </Link>
          </li>

          {/* <li className={`goong-navbar__item`}>
            <a
              href={`https://editor.goong.io?token=${getUserSession()?.token}`}
              target="_blank"
              rel="noopener noreferrer"
              className="goong-navbar__link-dashboard "
            >
              {t('studio')}
            </a>
          </li> */}
          {/* <li className={`goong-navbar__item ${isActive('/support/request')}`}>
            <Link to={'/support/request'} className="goong-navbar__link-dashboard">
              {t('support')}
            </Link>
          </li> */}
          <li className={`goong-navbar__item ${isActive('/support/request')}`}>
            <Link to={'/support/request'} className="goong-navbar__link-dashboard">
              {t('support')}
            </Link>
          </li>
          <li className={`goong-navbar__item`}>
            <Select bordered={false} onChange={changeLanguage} value={lang} style={{ minWidth: 100 }}>
              <Select.Option value="vi">
                <span className="goong-navbar__link-dashboard" style={{ padding: '3px 0px' }}>
                  Tiếng Việt
                </span>
              </Select.Option>
              <Select.Option value="en" className="goong-navbar__link">
                <span className="goong-navbar__link-dashboard" style={{ padding: '3px 0px' }}>
                  English
                </span>
              </Select.Option>
            </Select>
          </li>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <li className={`goong-navbar__item`}>
            <Select bordered={false} onChange={changeLanguage} value={lang} style={{ minWidth: 100 }}>
              <Select.Option value="vi">
                <span className="goong-navbar__link" style={{ padding: '6px 0px' }}>
                  Tiếng Việt
                </span>
              </Select.Option>
              <Select.Option value="en" className="goong-navbar__link">
                <span className="goong-navbar__link" style={{ padding: '6px 0px' }}>
                  English
                </span>
              </Select.Option>
            </Select>
          </li>
          <li className={`goong-navbar__item ${isActive('/')}`}>
            <a href={'https://goong.io/'} target="_blank" rel="noopener noreferrer" className="goong-navbar__link">
              {t('services')}
            </a>
          </li>
          <li className={`goong-navbar__item ${isActive('/mobile-apps')}`}>
            <a
              href={'https://www.goong.io/mobile-apps'}
              target="_blank"
              rel="noopener noreferrer"
              className="goong-navbar__link"
            >
              {t('mobile_apps')}
            </a>
          </li>
          <li className={`goong-navbar__item ${isActive('/live-map')}`}>
            <a href={'https://maps.goong.io/'} target="_blank" rel="noopener noreferrer" className="goong-navbar__link">
              {t('live_map')}
            </a>
          </li>
          <li className={`goong-navbar__item ${isActive('/documents')}`}>
            <a href={'https://docs.goong.io/'} target="_blank" rel="noopener noreferrer" className="goong-navbar__link">
              {t('documents')}
            </a>
          </li>
        </React.Fragment>
      )
    }
  }
  return (
    <ul id="collapsibleNavbar" className={`goong-navbar__nav collapse navbar-collapse`}>
      <ListLinks isLoggedIn={props.isLoggedIn} />
      <NavBarItemAvatar />
    </ul>
  )
}

const mapStateToProps = function(state) {
  return {
    isLoggedIn: state.userAction.isLoggedIn
  }
}

export default withRouter(connect(mapStateToProps)(NavBar))
