import { getUserSession } from '../../utils/user-func'

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case 'TOGGLE_SIGN_IN_POPUP':
      return {
        ...state,
        isShowSignInPopup: action.isShowSignInPopup
      }
    case 'TOGGLE_SIGN_UP_POPUP':
      return {
        ...state,
        isShowSignUpPopup: action.isShowSignUpPopup
      }
    case 'IS_USER_LOGGED_IN':
      return {
        ...state,
        isLoggedIn: action.isLoggedIn
      }
    case 'TIME_USER_CHANGE_PROFILE':
      return {
        ...state,
        timeChangeProfile: action.timeChangeProfile
      }
    default:
      return { ...state, isLoggedIn: !!getUserSession() }
    // return state
  }
}

export default userReducer
