import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import img_logo from '../../assets/images/goong-logo-2x.png'
import NavBar from './NavBar'

function Header() {
  return (
    <header className="goong-header header_fixed" style={{boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)'}}>
      <div className="container">
        <div className="goong-header__navbar">
          <div className="goong-navbar">
            <div className="goong-navbar__logo">
              <h1>
                <Link to={'/'}>
                  <img src={img_logo} alt={'goong-logo'} />
                </Link>
              </h1>
            </div>
            <NavBar />
          </div>
        </div>
      </div>
    </header>
  )
}

export default withRouter(Header)
